<script setup lang="ts">
import { ref, watch } from 'vue'
import { usePlayerStore } from '~/stores/usePlayerStore'

const playerStore = usePlayerStore()
const eventSource = ref<EventSource | null>(null)

const updateStreamTitle = (event: MessageEvent) => {
  const { streamTitle } = JSON.parse(event.data)
  if (playerStore.media) {
    playerStore.media.title = streamTitle
  }
}

watch(
  () => playerStore.media?.streamName,
  (streamName) => {
    if (!streamName) return

    if (eventSource.value) {
      eventSource.value.close()
    }

    eventSource.value = new EventSource(`https://api.zeno.fm/mounts/metadata/subscribe/${streamName}`)
    eventSource.value.onmessage = updateStreamTitle
  },
  { immediate: true }
)
</script>

<template>
  <div v-if="playerStore.media?.title" class="sm:text-lg font-medium truncate hyphens-auto">
    {{ playerStore.media?.title }}
  </div>
</template>

<style scoped></style>
